import { useState } from 'react';
import { NO_ITEM_SELECTED } from '../../../common/constants';

interface IMobileHeaderState {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  selectedMenu: string;
  setSelectedMenu: (value: string) => void;
  removeSelectedMenu: () => void;
}

export const useMobileBar = (): IMobileHeaderState => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedMenu, setSelectedMenu] = useState<string>(NO_ITEM_SELECTED);

  const removeSelectedMenu = () => setSelectedMenu(NO_ITEM_SELECTED);

  return {
    isOpen,
    setIsOpen,
    selectedMenu,
    setSelectedMenu,
    removeSelectedMenu,
  };
};
