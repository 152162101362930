import * as React from 'react';
import {
  Container,
  Menu,
  MenuBar,
  MenuBarItem,
  MenuItem,
  FlexGrid,
  FlexColumn,
  MenuGroup,
  Base,
} from '@stepstone-genesis/components';
import { IMainBar } from './IMainBar';

export const MainBar = (props: IMainBar): JSX.Element => {
  const { menuItems } = props;

  const getMenuItem = (menuItem, index): JSX.Element => {
    if (menuItem.hasChildren) {
      return (
        <Menu
          size={'large'}
          disclosure={
            <MenuBarItem
              className="mainbar-menu-item"
              px={'spaceL'}
              label={menuItem.text}
              a11yLabel={menuItem.text}
              isActive={menuItem.isActive}
              hasSubMenu={true}
              sx={{
                height: 'size80',
                paddingLeft: menuItem.indented ? 'spaceM' : '',
              }}
            />
          }
          key={index}
        >
          {menuItem.children.map((elm, i) => (
            <MenuItem
              className="mainbar-submenu-item"
              key={'s-' + index + '-' + i}
              href={elm.link}
              isSelected={elm.isActive}
              sx={{
                paddingLeft: elm.indented ? 'spaceM' : '',
              }}
            >
              <Base className="mainbar-submenu-item-label" sx={{ fontWeight: 'fontWeightBold', color: 'colorBlack' }}>{elm.text}</Base>
              { elm.description && <Base className="mainbar-submenu-item-sub-label" sx={{ fontSize: 'fontSizeS', color: 'colorGreyDark' }}>{ elm.description }</Base> }
            </MenuItem>
          ))}
        </Menu>
      );
    }
    if (menuItem.hasColumns) {
      return (
        <div className="column-menu-item" key={index}>
          <Menu
            className="mainbar-submenu"
            disclosure={
              <MenuBarItem
                className="mainbar-menu-item"
                px={'spaceL'}
                label={menuItem.text}
                a11yLabel={menuItem.text}
                isActive={menuItem.isActive}
                hasSubMenu={true}
                sx={{
                  height: 'size80',
                }}
              />
            }
            size={'large'}
          >
            <FlexGrid key={index}>
              {menuItem.columns.map((elm, i) => (
                <FlexColumn key={i}>
                  <MenuGroup
                    label={elm.text?.length > 0 ? elm.text : ''}
                    sx={{
                      fontSize: 'fontSizeM',
                      span: {
                        fontSize: 'fontSizeM',
                      },
                    }}
                  >
                    {elm.children.map((childElm, childI) => (
                      <MenuItem
                        className="mainbar-submenu-item"
                        key={childI}
                        isSelected={childElm.isActive}
                        href={childElm.link}
                        sx={{
                          wordBreak: 'break-word',
                        }}
                      >
                        {childElm.text}
                      </MenuItem>
                    ))}
                  </MenuGroup>
                </FlexColumn>
              ))}
            </FlexGrid>
          </Menu>
        </div>
      );
    }
    return (
      <MenuBarItem
        className="mainbar-menu-item"
        px={'spaceL'}
        key={index}
        label={menuItem.text}
        a11yLabel={menuItem.text}
        href={menuItem.link}
        as="a"
        isActive={menuItem.isActive}
        sx={{
          height: 'size80',
        }}
      />
    );
  };
  return (
    <Base
      data-testid="mainbar"
    >
      <Container
        custom={['containerS', 'containerM', 'containerL']}
        sx={{
          height: 'size80',
        }}
      >
        <Base 
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <MenuBar
            sx={{
              height: 'size80',
              alignItems: 'center',
            }}
          >
            {menuItems.map((menuItem, i) => getMenuItem(menuItem, i))}
          </MenuBar>
        </Base>
      </Container>
    </Base>
  );
};
