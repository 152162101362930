import * as React from 'react';
import { ThemeProvider, InstanceProvider } from "@stepstone-genesis/components";
import { core } from "@stepstone-genesis/dresscode";
import { customInstance } from './shared';
import { HeaderContainer } from './components/HeaderContainer';

const App: React.FC = () => {
  return (
    <InstanceProvider instance={customInstance}>
      <ThemeProvider theme={core}>
        <HeaderContainer />
      </ThemeProvider>
    </InstanceProvider>
  );
}

export { App };