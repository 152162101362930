import { IConfig, ILabels, IPHB2BHeader } from '../interfaces/IConfigs';

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars
  interface Window {
    bbh: {
      config: IConfig[];
      labels: ILabels[];
    };
    PHB2BHeader: IPHB2BHeader;
  }
}

export const getPHB2BHeader = (): IPHB2BHeader => {
  return window.PHB2BHeader;
};

export const getCountry = (): string => {
  return getPHB2BHeader().country.toLowerCase();
};

export const getBBH = () => {
  return window.bbh;
};

export const getCurrentConfig = () => {
  const country = getCountry();
  return getBBH().config.filter((c) => Object.keys(c)[0] === country)[0][country];
};

export const getCurrentLabels = () => {
  const country = getCountry();
  return getBBH().labels.filter((c) => Object.keys(c)[0] === country)[0][country];
};
