import * as React from 'react';
import { Responsive } from '@stepstone-genesis/components';
import { useHeaderContainer } from './hooks/useHeaderContainer';
import { MobileBar } from '../MobileBar';
import {
  getActiveMenuIndex,
  prepareMenuItems,
  isSimpleItemActive,
} from './helpers';
import { getPHB2BHeader } from '../../common/utils/helperPHB2BHeader';
import { TopBar } from '../TopBar';
import { UpperBar } from '../UpperBar';

export const HeaderContainer = (): JSX.Element => {
  const {
    menuItems,
    setmenuItems,
    findJobItem,
    setFindJobItem,
    contactItem,
    setContactItem,
    languageSwitcher,
    setLanguageSwitcher,
    currentLanguage,
    setCurrentLanguage,
    isUpperbarFixed,
    setIsUpperbarFixed,
  } = useHeaderContainer();

  const onScroll = () => {
    setIsUpperbarFixed(window.scrollY > 44);
  }

  React.useEffect(() => {
    if (menuItems.length === 0) {
      const activeMenuIndex = getActiveMenuIndex(
        getPHB2BHeader().menuItems,
        't',
      );
      setmenuItems(
        prepareMenuItems(getPHB2BHeader().menuItems, activeMenuIndex, 't'),
      );
    }
    if (findJobItem.text.length === 0) {
      setFindJobItem(isSimpleItemActive(getPHB2BHeader().findJobItem));
    }
    if (contactItem.text.length === 0) {
      setContactItem(isSimpleItemActive(getPHB2BHeader().contactItem));
    }
    if (!languageSwitcher || !languageSwitcher.links) {
      setLanguageSwitcher(getPHB2BHeader().languageSwitcher)
    }
    if (currentLanguage === '') {
      setCurrentLanguage(getPHB2BHeader().currentLang)
    }

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    }
  }, [menuItems.length, findJobItem.text.length, contactItem.text.length, languageSwitcher]);

  return (
    <>
      <Responsive aboveOrEqualTo="large">
        <TopBar 
          findJobItem={findJobItem}
          contactItem={contactItem}
          languageSwitcher={languageSwitcher}
          currentLanguage={currentLanguage}
          isFixed={isUpperbarFixed}
        />
        <UpperBar
          isFixed={isUpperbarFixed}
          menuItems={menuItems}
        />
      </Responsive>
      <Responsive below="large">
        <MobileBar
          menuItems={menuItems}
          findJobItem={findJobItem}
          contactItem={contactItem}
          languageSwitcher={languageSwitcher}
          currentLanguage={currentLanguage}
        />
      </Responsive>
    </>
  );
};
