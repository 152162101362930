import * as React from 'react';
import {
  ChevronBottomIcon,
  ChevronTopIcon,
  PersonIcon,
  BriefcaseIcon,
  ChatIcon,
  GlobeIcon,
} from '@stepstone-genesis/icons';
import {
  Base,
  Menu,
  MenuItem,
  MenuBar,
  MenuBarItem,
  MenuDivider,
  Text,
  List,
  ListItem,
  Anchor,
  Divider,
  Button,
  Stack,
} from '@stepstone-genesis/components';
import Cookies from 'js-cookie';
import { IMobileBar, IMobileBarColumn } from './IMobileBar';
import { Logo } from '../Logo';
import { useMobileBar } from './hooks/useMobileBar';
import { HamburgerItem } from '../HamburgerItem';
import { IMenuItem } from '../../common/interfaces/IMenuItem';
import { translate, hasTranslation } from '../../common/utils/translate';
import { gradientBackground } from '../../common/assets/gradient-background';

export const MobileBar = (props: IMobileBar): JSX.Element => {
  const {
    isOpen,
    setIsOpen,
    selectedMenu,
    setSelectedMenu,
    removeSelectedMenu,
  } = useMobileBar();
  const { menuItems, findJobItem, contactItem, languageSwitcher, currentLanguage } = props;

  const updateLangCookie = (lang: string) => {
    Cookies.set('STEPSTONEV5LANG', lang.toLowerCase(), { expires: 365 });
  };

  const handleClick = (event, hasChildren, hasColumns) => {
    if (!hasChildren && !hasColumns) {
      return;
    }
    event.preventDefault();

    if ('m-' + event.currentTarget.dataset.count === selectedMenu) {
      return removeSelectedMenu();
    }
    setSelectedMenu('m-' + Number(event.currentTarget.dataset.count));
  };

  const getMobileSubMenu = (items: IMenuItem[], index): JSX.Element => (
    <List
      key={'m-' + index}
      variant={'unordered'}
      styleType={'none'}
      sx={{
        margin: '0 !important',
        padding: '0',
      }}
    >
      {items.map((item, i) => (
        <ListItem
          key={'msm-' + i}
          sx={{
            margin: '0',
          }}
        >
          <Anchor
            href={item.link}
            sx={{
              color: 'colorBlack',
              textDecoration: 'none',
              fontSize: 'fontSizeL',
              width: '100%',
              display: 'block',
              paddingTop: 'spaceS',
              paddingBottom: 'spaceS',
              paddingLeft: 'spaceM',
            }}
          >
            {item.text}
          </Anchor>
        </ListItem>
      ))}
    </List>
  );

  const getMobileSubMenuColumns = (itemColumns: [], index) =>
    itemColumns.map((column: IMobileBarColumn, cIndex) => (
      <Base
        key={'msc-' + cIndex}
        sx={{
          maxHeight: selectedMenu === 'm-' + index ? '800px' : '0px',
          transition: 'max-height 0.3s ease-in-out, opacity 0.3s ease-in-out 0.3s',
          overflow: 'hidden',
          opacity: selectedMenu === 'm-' + index ? 1 : 0,
        }}
      >
        <Text
          sx={{
            color: 'colorBlack',
            textDecoration: 'none',
            width: '100%',
            fontSize: 'fontSizeL',
            fontWeight: 'fontWeightBold',
            paddingTop: 'spaceS',
            paddingBottom: 'spaceS',
            paddingLeft: 'spaceM',
          }}
        >
          <span>{column.text}</span>
        </Text>
        {getMobileSubMenu(column.children, index)}
      </Base>
    ));

  const getMobileMenuItems = (items: IMenuItem[]): JSX.Element => (
    <>
      {items.map((item, i) => (
        <ListItem
          key={'mmi-' + i}
          sx={{
            fontSize: 'fontSizeL',
            fontWeight: 'fontWeightBold',
            position: 'relative',
            color: 'colorBlack',
          }}
        >
          <Anchor
            href={item.link}
            data-count={i}
            onClick={(event) =>
              handleClick(event, item.hasChildren, item.hasColumns)
            }
            sx={{
              color: 'colorBlack',
              textDecoration: 'none',
              width: '100%',
              display: 'block',
              paddingTop: 'spaceS',
              paddingBottom: 'spaceS',
              paddingLeft: 'spaceM',
            }}
          >
            {item.text}
            {
              (item.hasChildren || item.hasColumns) &&
              (selectedMenu === 'm-' + i ? (
                <ChevronTopIcon
                  size={'large'}
                  sx={{
                    marginRight: 'spaceM',
                    position: 'absolute',
                    right: '0',
                    top: 'spaceS',
                  }}
                />
              ) : (
                <ChevronBottomIcon
                  size={'large'}
                  sx={{
                    marginRight: 'spaceM',
                    position: 'absolute',
                    right: '0',
                    top: 'spaceS',
                  }}
                />
              ))
            }
          </Anchor>
          
          {item.children && 
            <Base
              sx={{
                maxHeight: selectedMenu === 'm-' + i ? '800px' : '0px',
                transition: 'max-height 0.3s ease-in-out, opacity 0.3s ease-in-out 0.3s',
                overflow: 'hidden',
                opacity: selectedMenu === 'm-' + i ? 1 : 0,
              }}
            >
              {getMobileSubMenu(item.children, i)}
            </Base>
          }
          {item.columns && getMobileSubMenuColumns(item.columns, i)}
          <Divider
            isInverted
            orientation="horizontal"
            spacing="spaces"
            sx={{
              marginTop: 'spaceS',
              marginBottom: 'spaceS',
            }}
          ></Divider>
        </ListItem>
      ))}
    </>
  );

  return (
    <Base
      data-testid="mobilebar"
      className="mobilebar"
      sx={{
        height: 'size80',
        position: 'fixed',
        width: '100%',
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',
        borderBottomColor: 'colorGreyLighter',
        display: 'flex',
        zIndex: 'zIndex100',
      }}
    >
      <Base
        sx={{
          height: '100%',
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          paddingLeft: '16px',
          position: 'relative',
          zIndex: 'zIndex100',
        }}
      >
        <Logo />
        <Base
          sx={{
            display: 'flex',
            height: '100%',
            alignItems: 'center',
          }}
        >
          <MenuBar
            isInline={false}
          >
            <MenuBarItem
              className="login-button"
              icon={PersonIcon}
              a11yLabel={translate('loginItem.login')}
              href={translate('loginItem.loginLink')}
              as="a"
            />
            <HamburgerItem isOpen={isOpen} setIsOpen={setIsOpen} />
          </MenuBar>
        </Base>
      </Base>
      <Base
        className='mobile-menu-background'
        sx={{
          backgroundImage: `url('${gradientBackground}')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          transition: 'height 0.3s ease-out',
          height: isOpen ? '100%' : 'size80',
          position: 'fixed',
          top: '0',
          width: '100%',
          zIndex: 'zIndex90',
        }}
      ></Base>
      <Base
        className='mobile-menu'
        data-testid="mobilemenu"
        sx={{
          transition: isOpen ? 'opacity 0.3s ease-in-out 0.3s' : 'opacity 0.3s ease-in-out 0s',
          height: 'calc(100% - 96px)',
          width: '100%',
          margin: '0',
          opacity: isOpen ? '1' : '0',
          padding: '0',
          position: 'fixed',
          top: isOpen ? '96px' : '-100%',
          overflow: 'auto',
          zIndex: 'zIndex90',
        }}
      >
        <Base
          sx={{
            minHeight: '100%',
            paddingBottom: 'spaceXxl',
            position: 'relative',
          }}
        >
          <List
            variant={'unordered'}
            styleType={'none'}
            flxWrap={'nowrap'}
            sx={{
              margin: '0',
              padding: '0',
            }}
          >
            {getMobileMenuItems(menuItems)}
          </List>

          <Base
            sx={{
              paddingTop: 'spaceXl',
              paddingLeft: 'spaceM',
              paddingRight: 'spaceM',
            }}  
          >
            { hasTranslation('buyAd.label') && 
              <Button
                className="buy-ad-button"
                isFullWidth
                a11yLabel={translate('buyAd.label')}
                href={translate('buyAd.link')}
                as="a"
                sx={{
                  marginBottom: 'spaceM',
                }}
              >
                {translate('buyAd.label')}
              </Button> 
            }
            <Button
              isFullWidth
              variant="secondary"
              a11yLabel={translate('loginItem.login')}
              href={translate('loginItem.loginLink')}
              as="a"
              sx={{
                marginBottom: 'spaceL'
              }}
            >
              {translate('loginItem.login')}
            </Button>

            <Stack direction="horizontal" flxDirection="row" flxJustify="center">
              <MenuBar>
                <MenuBarItem
                  isActive={contactItem.isSelected}
                  label={
                    <Text sx={{ fontSize: 'fontSizeM' }}>{ contactItem.text }</Text> 
                  }
                  icon={ () => (<ChatIcon title="" sx={{ marginRight: 'spaceS' }} size="medium" />) }
                  a11yLabel={contactItem.text}
                  href={contactItem.link}
                  as="a"
                  sx={{
                    fontSize: 'fontSizeM',
                  }}
                />

                <MenuDivider 
                  color="colorBlack"
                  sx={{ 
                    height: 'size20'
                  }}
                />

                <MenuBarItem
                  isActive={findJobItem.isSelected}
                  label={findJobItem.text}
                  icon={ () => (<BriefcaseIcon title="" sx={{ marginRight: 'spaceS' }} size="medium" />) }
                  a11yLabel={findJobItem.text}
                  href={findJobItem.link}
                  as="a"
                />
              </MenuBar>
            </Stack>
          </Base>

          <Base
            sx={{
              background: 'colorOffWhite',
              borderRadius: 'radiusRound',
              position: 'absolute',
              bottom: 'spaceL',
              left: 'spaceM',
            }}
          >
            <MenuBar>
              { languageSwitcher && languageSwitcher.links != undefined && languageSwitcher.display && <Menu
                disclosure={
                  <MenuBarItem
                    label={ <Text sx={{ fontSize: 'fontSizeS', fontWeight: 'fontWeightBold' }} textTransform="uppercase">{ currentLanguage }</Text> }
                    icon={ () => (<GlobeIcon title="" sx={{ marginRight: 'spaceS' }} />) }
                    a11yLabel={currentLanguage}
                    sx={{
                      height: 'size40',
                      paddingLeft: 'spaceM',
                      paddingRight: 'spaceM',
                    }}
                  />
                }
                size='small'>
                {languageSwitcher.links.map((elm) => (
                  <MenuItem
                    key={'language-' + elm.language}
                    href={elm.link}
                    disabled={elm.text == currentLanguage}
                    onClick={() => updateLangCookie(elm.language)}
                  >
                    <Text sx={{ fontSize: 'fontSizeS' }}>
                      {elm.text}
                    </Text>
                  </MenuItem>
                ))}
              </Menu>}
            </MenuBar>
          </Base>
        </Base>
      </Base>
    </Base>
  );
};
