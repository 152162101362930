import {
  getBBH,
  getCurrentConfig,
  getCurrentLabels,
  getPHB2BHeader,
} from './helperPHB2BHeader';

const getParsedLang = (): string => {
  const searchParams = new URLSearchParams(location.search);
  const lang = searchParams.get('lang');
  return lang || '';
};
const isLanguageAvailable = (lang): boolean => {
  if (
    getBBH() &&
    getBBH().config &&
    getCurrentConfig() &&
    getCurrentConfig().availableLanguages
  ) {
    return (
      getCurrentConfig().availableLanguages.find((elem) => elem === lang) !==
      undefined
    );
  }
  return false;
};
export const selectAppLanguage = (): string => {
  let lang = getParsedLang();
  if (lang === '') {
    lang = getPHB2BHeader().currentLang;
  }
  return isLanguageAvailable(lang) ? lang : getCurrentConfig().defaultLanguage;
};
export const getLabel = (labelName: string): string => {
  const lang = selectAppLanguage();
  if (
    getBBH() &&
    getBBH().labels &&
    getCurrentLabels() &&
    getCurrentLabels()[lang]
  ) {
    return getCurrentLabels()[lang][labelName] || labelName;
  }
  return labelName;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const translate = (
  labelName: string
): string => {
  return getLabel(labelName);
};

export const hasTranslation = (
  labelName: string
): boolean => {
  const translation = translate(labelName);
  return translation != labelName;
};
