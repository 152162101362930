import * as React from 'react';

export const BrandLogo = (): JSX.Element => (
  <svg width="256" height="48" viewBox="0 0 256 48" fill="none" xmlns="http://www.w3.org/2000/svg" data-testid="brandlogo">
      <path d="M116.798 23.2828V24.0374C116.798 24.3491 116.665 24.4821 116.354 24.4821H99.4266C99.8705 27.4587 102.004 29.5912 105.291 29.5912C107.448 29.5912 109.011 28.9955 110.141 27.6825L110.142 27.6829C110.246 27.5574 110.4 27.4759 110.574 27.4759C110.689 27.4759 110.797 27.5118 110.887 27.5725L110.895 27.5779C110.897 27.5796 110.899 27.5817 110.901 27.5834C110.899 27.5817 110.897 27.5796 110.895 27.5779L110.901 27.5825L114.05 29.7874L114.054 29.7899L114.062 29.7958L114.062 29.7966C114.207 29.8999 114.302 30.0689 114.302 30.2609C114.302 30.378 114.267 30.4872 114.206 30.5776C114.204 30.5809 114.201 30.5838 114.199 30.5872C114.191 30.5972 114.183 30.6081 114.175 30.6177C112.101 33.1622 109.122 34.3895 105.246 34.3895C98.8042 34.3895 93.8285 29.6803 93.8281 22.7938C93.8281 15.9065 98.982 11.1973 105.29 11.1973C112.578 11.1977 116.798 16.7954 116.798 23.2828ZM111.111 20.0389C110.312 17.6404 108.446 15.996 105.291 15.996C102.759 15.996 100.492 17.5069 99.604 20.0389H111.111Z" fill="#00217A"/>
      <path d="M255.918 23.2828V24.0374C255.918 24.3491 255.785 24.4821 255.474 24.4821H238.546C238.99 27.4587 241.124 29.5912 244.411 29.5912C246.568 29.5912 248.131 28.9955 249.261 27.6825L249.262 27.6829C249.366 27.5574 249.519 27.4759 249.693 27.4759C249.809 27.4759 249.917 27.5118 250.007 27.5725L250.014 27.5779C250.017 27.5796 250.019 27.5817 250.021 27.5834C250.019 27.5817 250.017 27.5796 250.014 27.5779L250.021 27.5825L253.17 29.7874L253.174 29.7899L253.182 29.7958L253.181 29.7966C253.327 29.8999 253.422 30.0689 253.422 30.2609C253.422 30.378 253.387 30.4872 253.326 30.5776C253.324 30.5809 253.321 30.5838 253.319 30.5872C253.311 30.5972 253.303 30.6081 253.295 30.6177C251.221 33.1622 248.242 34.3895 244.367 34.3895C237.924 34.3895 232.948 29.6803 232.948 22.7938C232.948 15.9065 238.102 11.1973 244.411 11.1973C251.698 11.1977 255.918 16.7954 255.918 23.2828ZM250.231 20.0389C249.432 17.6404 247.566 15.996 244.411 15.996C241.879 15.996 239.613 17.5069 238.724 20.0389H250.231Z" fill="#00217A"/>
      <path d="M91.3904 28.8809C91.3109 28.8813 91.2268 28.8855 91.1072 28.9248C89.5524 29.5029 88.5744 29.5912 87.1978 29.5912C84.9766 29.5912 84.132 28.3476 84.132 25.9482V16.4406H90.8411C91.0863 16.4406 91.285 16.2419 91.285 15.9968V12.0857C91.285 11.8406 91.0863 11.6415 90.8407 11.6415H84.132V5.24313C84.132 4.99801 83.9333 4.79932 83.6882 4.79932H79.2446C78.9995 4.79932 78.8008 4.99801 78.8008 5.24313V26.9262C78.8008 31.8579 81.9999 34.3899 86.2654 34.3895C87.9536 34.3895 90.0857 34.1682 91.3741 33.6792C91.7296 33.5458 91.7296 33.3237 91.7296 32.9238V29.2193C91.7296 29.0323 91.5778 28.88 91.3904 28.8809Z" fill="#00217A"/>
      <path d="M141.649 22.7938C141.649 29.9468 137.517 34.3895 131.518 34.3895C129.075 34.3895 127.032 33.6793 125.121 32.1684V42.2988C125.121 42.6096 124.987 42.7426 124.677 42.7426H120.233C119.923 42.7426 119.79 42.6091 119.79 42.2988V12.0862C119.79 11.7745 119.923 11.6415 120.233 11.6415H124.677C124.988 11.6415 125.121 11.7749 125.121 12.0862V13.4636C126.809 12.0862 128.853 11.1973 131.518 11.1973C137.517 11.1977 141.649 15.64 141.649 22.7938ZM136.051 22.7938C136.051 18.9726 133.962 15.9956 130.452 15.9956C126.942 15.9956 124.854 18.9722 124.854 22.7938C124.854 26.6142 126.942 29.5912 130.452 29.5912C133.962 29.5912 136.051 26.6146 136.051 22.7938Z" fill="#00217A"/>
      <path d="M221.479 11.1973C218.635 11.1973 216.591 12.1753 214.77 13.6853V12.0862C214.77 11.7745 214.636 11.6415 214.325 11.6415H209.882C209.572 11.6415 209.438 11.7749 209.438 12.0862V33.5015C209.438 33.8123 209.572 33.9453 209.882 33.9453H214.325C214.637 33.9453 214.77 33.8119 214.77 33.5015V23.4158C214.77 18.1285 217.303 15.9956 220.101 15.9956C222.545 15.9956 223.922 17.284 223.923 20.6166V33.5015C223.923 33.8123 224.056 33.9453 224.366 33.9453H228.81C229.121 33.9453 229.254 33.8119 229.254 33.5015V18.7058C229.254 14.0852 226.321 11.1973 221.479 11.1973Z" fill="#00217A"/>
      <path d="M205.514 22.7938C205.514 29.5469 200.404 34.3895 193.607 34.3895C186.809 34.3895 181.7 29.5473 181.7 22.7938C181.7 16.0399 186.809 11.1973 193.607 11.1973C200.405 11.1973 205.514 16.0399 205.514 22.7938ZM199.827 22.7938C199.827 18.7058 197.294 15.9956 193.607 15.9956C189.919 15.9956 187.387 18.7058 187.387 22.7938C187.387 26.8806 189.919 29.5912 193.607 29.5912C197.294 29.5912 199.827 26.881 199.827 22.7938Z" fill="#00217A"/>
      <path d="M59.9389 32.8287C61.707 33.9606 65.3295 35.0867 69.8246 33.8493C73.0288 32.8893 75.5231 30.6414 75.4511 27.2377C75.4511 23.0542 72.2348 21.4107 67.4265 20.6795C62.9281 19.9952 62.1099 19.0749 62.0977 17.9706C62.0831 16.6567 63.1761 16.0301 64.6272 15.8356C66.85 15.5374 68.8374 16.5216 70.1358 18.24C70.3696 18.5496 70.667 18.6499 70.9875 18.4638C70.9875 18.4638 74.2502 16.5978 74.5301 16.4355C74.8099 16.2727 74.9149 15.8921 74.7388 15.6307C73.6433 14.0984 72.2198 12.8552 70.4131 12.1537C68.6069 11.4522 67.1952 11.1808 65.4111 11.1975C61.272 11.1975 56.7661 13.1987 56.7661 18.1036C56.8029 21.4626 59.1048 23.6424 64.4913 24.512C68.6747 25.1742 70.1124 26.0426 70.1199 27.2799C70.1308 29.028 68.1321 29.6747 65.573 29.5316C63.5455 29.4183 62.2563 28.406 61.1754 26.8654C61.006 26.6115 60.6153 26.5391 60.3522 26.6897L56.6703 28.7979L56.6795 28.8004C56.398 28.9577 56.2486 29.3768 56.4536 29.6328C57.701 31.1893 58.1005 31.6516 59.9389 32.8287Z" fill="#00217A"/>
      <path d="M179.227 28.8809C179.147 28.8813 179.063 28.8855 178.944 28.9248C177.389 29.5029 176.411 29.5912 175.034 29.5912C172.813 29.5912 171.968 28.3476 171.968 25.9482V16.4406H178.678C178.923 16.4406 179.121 16.2419 179.121 15.9968V12.0857C179.121 11.8406 178.923 11.6415 178.677 11.6415H171.968V5.24313C171.968 4.99801 171.769 4.79932 171.524 4.79932H167.081C166.835 4.79932 166.637 4.99801 166.637 5.24313V26.9262C166.637 31.8579 169.836 34.3899 174.101 34.3895C175.79 34.3895 177.922 34.1682 179.21 33.6792C179.566 33.5458 179.566 33.3237 179.566 32.9238V29.2193C179.566 29.0323 179.414 28.88 179.227 28.8809Z" fill="#00217A"/>
      <path d="M147.775 32.8287C149.543 33.9606 153.165 35.0867 157.661 33.8493C160.865 32.8893 163.359 30.6414 163.287 27.2377C163.287 23.0542 160.071 21.4107 155.262 20.6795C150.764 19.9952 149.946 19.0749 149.934 17.9706C149.919 16.6567 151.012 16.0301 152.463 15.8356C154.686 15.5374 156.673 16.5216 157.972 18.24C158.206 18.5496 158.503 18.6499 158.823 18.4638C158.823 18.4638 162.086 16.5978 162.366 16.4355C162.646 16.2727 162.751 15.8921 162.575 15.6307C161.479 14.0984 160.056 12.8552 158.249 12.1537C156.442 11.4522 155.031 11.1808 153.247 11.1975C149.108 11.1975 144.602 13.1987 144.602 18.1036C144.639 21.4626 146.941 23.6424 152.327 24.512C156.511 25.1742 157.948 26.0426 157.956 27.2799C157.967 29.028 155.968 29.6747 153.409 29.5316C151.381 29.4183 150.092 28.406 149.011 26.8654C148.842 26.6115 148.451 26.5391 148.188 26.6897L144.506 28.7979L144.515 28.8004C144.234 28.9577 144.085 29.3768 144.29 29.6328C145.537 31.1893 145.936 31.6516 147.775 32.8287Z" fill="#00217A"/>
      <path d="M22.2053 14.8693C21.2236 10.0676 26.6924 7.61216 33.3422 7.97692C39.8898 8.33582 46.8445 11.2351 47.2159 14.3815C47.5594 17.2904 40.6336 18.9686 34.6309 18.9686C28.8856 18.9686 22.906 18.2977 22.2053 14.8693Z" fill="#00217A"/>
      <path d="M20.0591 8.34186C20.4372 4.74531 14.0406 4.10573 9.66893 5.44596C5.29727 6.7862 1.98224 9.7737 4.98438 11.1896C6.6672 11.9836 9.97303 12.422 13.8436 11.7882C17.7141 11.1545 19.8466 10.3614 20.0591 8.34186Z" fill="#00217A"/>
      <path d="M34.4633 29.3601C33.7229 23.2027 26.8938 21.2894 19.4656 22.2113C12.0374 23.1332 4.01063 26.8921 0.874217 32.079C-3.35187 39.0684 5.58847 42.9055 15.314 42.7369C25.0394 42.5683 35.5501 38.3941 34.4633 29.3601Z" fill="#00217A"/>
  </svg>
)