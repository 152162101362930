import * as React from 'react';
import { MenuBarItem } from '@stepstone-genesis/components';
import { MenuIcon, RemoveIcon } from '@stepstone-genesis/icons';
import { IHamburgerItemProps } from './IHamburgerItem';
import { translate } from '../../common/utils/translate';

export const HamburgerItem = (props: IHamburgerItemProps): JSX.Element => {
  const { isOpen, setIsOpen } = props;

  const onMenuToggle = () => setIsOpen(!isOpen);

  return (
    <MenuBarItem
      data-testid="hamburger"
      icon={isOpen ? RemoveIcon : MenuIcon}
      a11yLabel={translate(isOpen ? 'hamburgerItem.close' : 'hamburgerItem.menu')}
      onClick={onMenuToggle}
    />
  );
};
