import * as React from 'react';
import {
  Base,
  Text,
  Menu,
  MenuItem,
  MenuBar,
  MenuBarItem,
  MenuDivider,
} from '@stepstone-genesis/components';
import Cookies from 'js-cookie';
import { BriefcaseIcon, ChatIcon, GlobeIcon } from '@stepstone-genesis/icons';
import { ITopBar } from './ITopBar';

export const TopBar = (props: ITopBar) => {
  const { findJobItem, contactItem, languageSwitcher, currentLanguage, isFixed } = props;

  const updateLangCookie = (lang: string) => {
    Cookies.set('STEPSTONEV5LANG', lang.toLowerCase(), { expires: 365 });
  };

  return (
    <Base
      sx={{
        display: 'flex',
        justifyContent: 'right',
        alignItems: 'right',
        height: '44px',
        backgroundColor: 'colorOffWhite',
        margin: 0,
        padding: '0 24px',
        top: isFixed ? '-44px' : '0px',
        position: 'fixed',
        transition: 'top .3s',
        width: '100%',
        zIndex: 'zIndex10',
      }}
      role="navigation"
      aria-label="Top"
    >
      <MenuBar
        sx={{
          height: '100%',
        }}
      >
        <MenuBarItem
          isActive={contactItem.isSelected}
          label={
            <Text sx={{ fontSize: 'fontSizeS' }}>{ contactItem.text }</Text> 
          }
          icon={ () => (<ChatIcon title="" sx={{ marginRight: 'spaceS' }} />) }
          a11yLabel={contactItem.text}
          href={contactItem.link}
          as="a"
        />
        
        { languageSwitcher && languageSwitcher.links != undefined && languageSwitcher.display && <Menu
          size={'small'}
          disclosure={
            <MenuBarItem
              label={ <Text sx={{ fontSize: 'fontSizeS' }} textTransform="uppercase">{ currentLanguage }</Text> }
              icon={ () => (<GlobeIcon title="" sx={{ marginRight: 'spaceS' }} />) }
              a11yLabel={currentLanguage} />
          }>
          {languageSwitcher.links.map((elm) => (
            <MenuItem
              key={'language-' + elm.language}
              href={elm.link}
              disabled={elm.text == currentLanguage}
              onClick={() => updateLangCookie(elm.language)}
            >
              <Text sx={{ fontSize: 'fontSizeS' }}>
                {elm.text}
              </Text>
            </MenuItem>
          ))}
        </Menu>}

        <MenuDivider 
          color="colorBlack"
          sx={{ 
            height: 'size20'
          }}
        />

        <MenuBarItem
          isActive={findJobItem.isSelected}
          label={ <Text sx={{ fontSize: 'fontSizeS' }}>{findJobItem.text}</Text> }
          icon={ () => (<BriefcaseIcon title="" sx={{ marginRight: 'spaceS' }} />) }
          a11yLabel={findJobItem.text}
          href={findJobItem.link}
          as="a"
        />
      </MenuBar>
    </Base>
  );
};
