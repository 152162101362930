import { useState } from 'react';
import {
  NO_ITEM_SELECTED,
  NO_ITEM_SELECTED_INT,
} from '../../../common/constants';
import { IMenuItem } from '../../../common/interfaces/IMenuItem';
import { ISimpleMenuItem } from 'common/interfaces/ISimpleMenuItem';
import { ILanguageSwitcher } from 'common/interfaces/ILanguageSwitcher';

interface IHeaderState {
  selectedTopItem: string;
  setSelectedTopItem: (value: string) => void;
  selectedBottomItem: number;
  setSelectedBottomItem: (value: number) => void;
  menuItems: IMenuItem[];
  setmenuItems: (value: IMenuItem[]) => void;
  findJobItem: ISimpleMenuItem;
  setFindJobItem: (value: ISimpleMenuItem) => void;
  contactItem: ISimpleMenuItem;
  setContactItem: (value: ISimpleMenuItem) => void;
  languageSwitcher: ILanguageSwitcher;
  setLanguageSwitcher: (value: ILanguageSwitcher) => void;
  currentLanguage: string;
  setCurrentLanguage: (value: string) => void;
  isUpperbarFixed: boolean,
  setIsUpperbarFixed: (value: boolean) => void;
}

export const useHeaderContainer = (): IHeaderState => {
  const [selectedTopItem, setSelectedTopItem] =
    useState<string>(NO_ITEM_SELECTED);
  const [selectedBottomItem, setSelectedBottomItem] =
    useState<number>(NO_ITEM_SELECTED_INT);
  const [menuItems, setmenuItems] = useState<IMenuItem[]>([]);
  const [findJobItem, setFindJobItem] = useState<ISimpleMenuItem>({
    text: '',
    link: '#',
    isSelected: false,
  });
  const [contactItem, setContactItem] = useState<ISimpleMenuItem>({
    text: '',
    link: '#',
    isSelected: false,
  });
  const [languageSwitcher, setLanguageSwitcher] = useState<ILanguageSwitcher>({
    display: false,
    text: 'Language',
  });
  const [currentLanguage, setCurrentLanguage] = useState<string>('');
  const [isUpperbarFixed, setIsUpperbarFixed] = useState<boolean>(false);

  return {
    selectedTopItem,
    setSelectedTopItem,
    selectedBottomItem,
    setSelectedBottomItem,
    menuItems,
    setmenuItems,
    findJobItem,
    setFindJobItem,
    contactItem,
    setContactItem,
    languageSwitcher,
    setLanguageSwitcher,
    currentLanguage,
    setCurrentLanguage,
    isUpperbarFixed,
    setIsUpperbarFixed,
  };
};
