import { ISimpleMenuItem } from 'common/interfaces/ISimpleMenuItem';
import { IMenuItem } from '../../common/interfaces/IMenuItem';

export const prepareMenuItems = (
  menuItems: IMenuItem[],
  activeItemIndex: string[],
  menuType: string,
): IMenuItem[] => {
  return menuItems.map((menuItem, index) => {
    const { children, ...item } = menuItem;
    const { columns, ...item2 } = menuItem;
    const hasChildren = !!(children?.length);
    const hasColumns = !!(columns?.length);
    const isActive =
      activeItemIndex.length !== 0 &&
      menuType + '-' + index === activeItemIndex[0];
    const activeSubItemIndex =
      isActive && (hasChildren || hasColumns) ? [activeItemIndex[1]] : [];
    item.text = item.text.replace('&#038;', '&');
    item2.text = item2.text.replace('&#038;', '&');
    let result = {
      ...{ indented: false, description: '' },
      ...item,
      ...item2,
      hasChildren,
      hasColumns,
      isActive,
    } as IMenuItem;
    if (hasChildren) {
      result = {
        ...result,
        children: prepareMenuItems(
          children as IMenuItem[],
          activeSubItemIndex,
          's' + menuType,
        ),
      } as IMenuItem;
    }
    if (hasColumns) {
      const currentColumns: Array<object> = [];
      for (let columnIndex = 0; columnIndex < columns.length; columnIndex++) {
        const column = columns[columnIndex];
        currentColumns.push({
          ...column,
          children: prepareMenuItems(
            column.children as IMenuItem[],
            activeSubItemIndex,
            'sc' + menuType + '-' + columnIndex,
          ),
        });
      }
      result = {
        ...result,
        columns: currentColumns,
      } as IMenuItem;
    }
    return result;
  });
};

export const getActiveMenuIndex = (
  menuItems: IMenuItem[],
  menuName: string,
  location = window.location,
): string[] => {
  const linksToCurrentPage = linksToPage(location);
  for (let index = 0; index < menuItems.length; index++) {
    const menuItem = menuItems[index];
    const children = menuItem.children;
    const columns = menuItem.columns;

    if (linksToCurrentPage(menuItem)) {
      return [menuName + '-' + index];
    }

    if (children) {
      const subIndex = children.findIndex(linksToCurrentPage);
      if (subIndex !== -1) {
        return [
          menuName + '-' + index, 's' + menuName + '-' + subIndex
        ];
      }
    } else if (columns) {
      for (let cIndex = 0; cIndex < columns.length; cIndex++) {
        const column = columns[cIndex];
        const subIndex = column.children.findIndex(linksToCurrentPage);

        if (subIndex !== -1) {
          return [
            menuName + '-' + index,
            'sc' + menuName + '-' + cIndex + '-' + subIndex,
          ];
        }
      }
    }
  }
  return [];
};

export const linksToPage = (location: Location) => {
  return (item: IMenuItem) => {
    if (!item.link) {
      return false;
    }
    const url = new URL(item.link, location.href);
    return url.pathname === location.pathname;
  };
};

export const isSimpleItemActive = (item: ISimpleMenuItem): ISimpleMenuItem => {
  const location = window.location;
  const url = new URL(item.link, location.href);
  const isJobItemActive = url.pathname === location.pathname;
  return {
    ...item,
    isSelected: isJobItemActive,
  };
};
