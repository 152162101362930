import * as React from 'react';
import { Anchor } from '@stepstone-genesis/components';
import { BrandLogo } from '../../common/assets/brand-logo';
import { getPHB2BHeader } from '../../common/utils/helperPHB2BHeader';

export const Logo = (): JSX.Element => {
  const logolink = getPHB2BHeader().logoLink;

  return <Anchor href={logolink} sx={{
    width: ['160px', '160px', '160px', '256px'],
    display: 'flex',
    alignItems: 'center'
  }}>
    <BrandLogo />
  </Anchor>;
};
