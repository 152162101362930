import 'core-js/features/url';
import 'es6-promise/auto';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { App } from './App';


declare const APP_NAME: string;

ReactDOM.render(
  <App></App>,
  document.querySelector(`#${APP_NAME}__container`),
);
