import * as React from 'react';
import {
  Base,
  FlexGrid,
  FlexItem,
  Button,
} from '@stepstone-genesis/components';
import { IUpperBar } from './IUpperBar';
import { MainBar } from '../MainBar';
import { Logo } from '../Logo';
import { translate, hasTranslation } from '../../common/utils/translate';
import { gradient } from '../../common/assets/gradient';

export const UpperBar = (props: IUpperBar): JSX.Element => {
  const { isFixed, menuItems } = props;

  return (
    <Base
      data-testid="upperbar"
      sx={{
        paddingX: 'spaceL',
        width: '100%',
        backgroundImage: `url('${gradient}')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        display: 'flex',
        height: 'size100',
        top: isFixed ? '0px' : '44px',
        position: 'fixed',
        transition: 'top .3s'
      }}
    >
      <FlexGrid
        sx={{
          width: '100%',
        }}
        flxJustify="flex-start"
        flxItems="center"
      >
        <FlexItem>
          <Logo />
        </FlexItem>
        <FlexItem>
          <MainBar menuItems={menuItems} />
        </FlexItem>
        <FlexItem
          flxJustify="flex-end"
          sx={{
            display: 'flex',
            marginLeft: 'auto',
          }}
        >

          <Button
            className="login-button"
            variant="secondary"
            a11yLabel={translate('loginItem.login')}
            href={translate('loginItem.loginLink')}
            as="a"
            sx={{
              marginRight: 'spaceM'
            }}
          >
            {translate('loginItem.login')}
          </Button>

          { hasTranslation('buyAd.label') && 
            <Button
              className="buy-ad-button"
              a11yLabel={translate('buyAd.label')}
              href={translate('buyAd.link')}
              as="a"
              sx={{
                color: 'colorWhite'
              }}
            >
              {translate('buyAd.label')}
            </Button> 
          }

        </FlexItem>
      </FlexGrid>
    </Base>
  );
};
